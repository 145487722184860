import React from 'react';
import './style.css';
import { FaPhoneAlt, FaFacebookF, FaMapMarkerAlt, FaTwitter, FaLinkedinIn, FaYoutube, FaWhatsapp } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';
import ReactWhatsapp from 'react-whatsapp';

const Footer = () => {
    return (
        <div className="footer-section">
            <div className="footer">
                <div className="foots">
                    <div className="row m-3">
                        <div className="col-lg-3 col-md-6 mb-3">
                            <div className="footer-logo">
                                <h4>Hello World Tour
                                </h4>
                            </div>
                            <div className='footer-text'>
                                <p> Established with the aim of fulfilling unfulfilled desire of
                                    hospitality and services of our decent customers, facilitate with skilled owners
                                    and staffs who have devoted long period of their time with dedication in this
                                    particular business.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 mb-3">
                            <div className="quick-link">
                                <div className="h4">
                                    <h4>Quick Link</h4>
                                </div>
                                <ul>
                                    <li>Home</li>
                                    <li><Link to='/all-blogs' className='text-white'>Blog</Link></li>
                                    <li>Tours</li>
                                    <li><Link to='/about-us' className='text-white'>About Us</Link></li>
                                    <li><Link to='/contact-us' className='text-white'>Contact</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-3">
                            <div className="contact">
                                <div className="h4">
                                    <h4>Contact Us</h4>
                                </div>
                                <div className="contacts">
                                    <p className="location"><FaMapMarkerAlt /> Kathmandu Metropolitan city, Nepal</p>
                                    <p className="phone"><FaPhoneAlt /> +977-984-1071644</p>
                                    <div className="what d-flex">
                                        <div className="whats-app">
                                            <p className="phone"><FaWhatsapp className='wtapp' /></p>
                                            <ReactWhatsapp number="+66654565727" message="Hello!" />
                                        </div>
                                        <p>+66654565727 (Contact for Thailand)</p>
                                    </div>
                                    <p className="mail"><MdEmail /> info@helloworld.com.np</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-3">
                            <div className="find-us">
                                <div className="h4">
                                    <h4>Find Us</h4>
                                </div>
                                <div className="find">
                                    <a href="https://www.facebook.com/profile.php?id=100075874434194" target="__blank"><FaFacebookF className='ficon' /></a>
                                    <FaLinkedinIn className='ficon' />
                                    <FaTwitter className='ficon' />
                                    <FaYoutube className='ficon' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="footer-end">
                        <div className="copy-right">
                            <p>&copy;2022 Hello World Tours and Travels</p>
                        </div>
                        <div className="powered">
                            <p>Powered by <a href="http://www.sinepal.com/">Smart Innovation</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
